import React from "react";


export default class JobsWidget extends React.Component {

    onJobClicked = (e, url) => {
        e.preventDefault();
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    render() {
        return <>
            {this.props.jobs.length > 0 ? <>
                <div className="course-categories" data-visible={this.props.visible}>
                    <h4>Jobs you might be interested</h4>
                    <div className="list">
                        {this.props.jobs.length > 0 ? this.props.jobs.map((job, index) => (
                            <a key={index} rel="norefferer" onClick={(e) => this.onJobClicked(e, job.url)} className="category" href={job.url} dangerouslySetInnerHTML={{ __html: job.name }}></a>
                        )) : <></>}
                    </div>
                </div>

            </> : <></>}</>
    }
}